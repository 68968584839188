import { computed } from 'vue'
import { FieldCategoryEnum, TicketAasmStateEnum } from '@/gql/requests.generated'
import { DashboardTicket } from '@/gql/types'

export const ticketStates = (ticket: DashboardTicket) => {
  const isCrewTicket = computed(() => ticket.primaryFieldCategory?.key === FieldCategoryEnum.Crew)
  const isCompleted = computed(() => ticket.aasmState === TicketAasmStateEnum.Completed)
  const showEditButton = computed(() => ticket.isEditable && !isCrewTicket.value)
  const canEditTicket = computed(() => ticket.isEditable && !isCrewTicket.value && (isOwnTicket.value || hasNoOwnershipAndUnlocked.value))
  const isOwnTicket = computed(() => ticket.ownership === 'me')
  const hasNoOwnershipAndUnlocked = computed(() => ticket.ownership == null && ticket.aasmState === TicketAasmStateEnum.Unlocked)

  return {
    canEditTicket,
    isCompleted,
    isCrewTicket,
    hasNoOwnershipAndUnlocked,
    isOwnTicket,
    showEditButton,
  }
}
